<template>
  <div >
    <v-expansion-panels
      accordion
      flat
      v-model="panel"
      mandatory
      class="about-expension-panels"
      style="color: white !important;"
    >
      <v-expansion-panel v-for="(item, i) in faqQuestions" :key="i">
        <v-expansion-panel-header hide-actions>{{
          item.title
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ item.content }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  import  faqQuestions from "../../data/accordian_selfmanaged.json"
export default {
  data() {
    return {
      panel: 0,
      faqQuestions
      
    };
  },
};
</script>
